(function () {
  'use strict';

  class DiscussionsCtrl {
    constructor() {
      let vm = this;
      vm.ctrlName = 'DiscussionsCtrl';
    }
  }

  /**
   * @ngdoc object
   * @name dashboard.discussions.controller:DiscussionsCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard.discussions')
    .controller('DiscussionsCtrl', DiscussionsCtrl);
}());
